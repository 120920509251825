.protection-data-box{
    grid-column: 2/7;
    margin: 12px 0 20px;
}
.protection-text-one{
    color: #000;
    font-size: 15px;
}
.protection-sub-sub{
    color: #000;
    font-weight: 600;
    margin-top: 12px;
}
.protection-text{
    color: #696767;
    margin-top: 4px;
    font-size: 13px;
}
.protection-text-afz{
    color: #000;
    margin-top: 4px;
}
.protection-mobile-box{
    padding: 0 25px 0 20px;
}
.protection-title-mobile{
    font-size: 16px;
}

.six-logos-start{
    grid-row: 2;
    grid-column: 2/7;
    margin-top: 15%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 15px;    
}

.six-logos img{
   width: 100%;
}
.six-logos1{
    -webkit-animation: 10s ease 0s normal forwards infinite fadein1;
    animation: 10s ease 0s normal forwards infinite fadein1;
  
} 
@keyframes fadein1{
    0% { opacity:0; }
    5% { opacity:0; }
    20% { opacity:1; }
    95% { opacity:1; }
    100% { opacity:0; }
}

@-webkit-keyframes fadein1{
    0% { opacity:0; }
    5% { opacity:0; }
    20% { opacity:1; }
    95% { opacity:1; }
    100% { opacity:0; }
}
.six-logos2{
    -webkit-animation: 10s ease 0s normal forwards infinite fadein2;
    animation: 10s ease 0s normal forwards infinite fadein2;
  
} 
@keyframes fadein2{
    0% { opacity:0; }
    10% { opacity:0; }
    25% { opacity:1; }
    95% { opacity:1; }
    100% { opacity:0; }
}

@-webkit-keyframes fadein2{
    0% { opacity:0; }
    10% { opacity:0; }
    25% { opacity:1; }
    95% { opacity:1; }
    100% { opacity:0; }
}
.six-logos3{
    -webkit-animation: 10s ease 0s normal forwards infinite fadein3;
    animation: 10s ease 0s normal forwards infinite fadein3;
  
} 
@keyframes fadein3{
    0% { opacity:0; }
    15% { opacity:0; }
    30% { opacity:1; }
    95% { opacity:1; }
    100% { opacity:0; }
}

@-webkit-keyframes fadein3{
    0% { opacity:0; }
    15% { opacity:0; }
    30% { opacity:1; }
    95% { opacity:1; }
    100% { opacity:0; }
}
.six-logos4{
    -webkit-animation: 10s ease 0s normal forwards infinite fadein4;
    animation: 10s ease 0s normal forwards infinite fadein4;
  
} 
@keyframes fadein4{
    0% { opacity:0; }
    20% { opacity:0; }
    35% { opacity:1; }
    95% { opacity:1; }
    100% { opacity:0; }
}

@-webkit-keyframes fadein4{
    0% { opacity:0; }
    20% { opacity:0; }
    35% { opacity:1; }
    95% { opacity:1; }
    100% { opacity:0; }
}
.six-logos5{
    -webkit-animation: 10s ease 0s normal forwards infinite fadein5;
    animation: 10s ease 0s normal forwards infinite fadein5;
  
} 
@keyframes fadein5{
    0% { opacity:0; }
    25% { opacity:0; }
    40% { opacity:1; }
    95% { opacity:1; }
    100% { opacity:0; }
}

@-webkit-keyframes fadein5{
    0% { opacity:0; }
    25% { opacity:0; }
    40% { opacity:1; }
    95% { opacity:1; }
    100% { opacity:0; }
}
.six-logos6{
    -webkit-animation: 10s ease 0s normal forwards infinite fadein6;
    animation: 10s ease 0s normal forwards infinite fadein6;
  
} 
@keyframes fadein6{
    0% { opacity:0; }
    30% { opacity:0; }
    45% { opacity:1; }
    95% { opacity:1; }
    100% { opacity:0; }
}

@-webkit-keyframes fadein6{
    0% { opacity:0; }
    30% { opacity:0; }
    45% { opacity:1; }
    95% { opacity:1; }
    100% { opacity:0; }
}
.six-logos7{
    -webkit-animation: 10s ease 0s normal forwards infinite fadein7;
    animation: 10s ease 0s normal forwards infinite fadein7;
  
} 
@keyframes fadein7{
    0% { opacity:0; }
    35% { opacity:0; }
    50% { opacity:1; }
    95% { opacity:1; }
    100% { opacity:0; }
}

@-webkit-keyframes fadein7{
    0% { opacity:0; }
    35% { opacity:0; }
    50% { opacity:1; }
    95% { opacity:1; }
    100% { opacity:0; }
}
.six-logos8{
    -webkit-animation: 10s ease 0s normal forwards infinite fadein8;
    animation: 10s ease 0s normal forwards infinite fadein8;
  
} 
@keyframes fadein8{
    0% { opacity:0; }
    40% { opacity:0; }
    55% { opacity:1; }
    95% { opacity:1; }
    100% { opacity:0; }
}

@-webkit-keyframes fadein8{
    0% { opacity:0; }
    40% { opacity:0; }
    55% { opacity:1; }
    95% { opacity:1; }
    100% { opacity:0; }
}
.six-logos9{
    -webkit-animation: 10s ease 0s normal forwards infinite fadein9;
    animation: 10s ease 0s normal forwards infinite fadein9;
  
} 
@keyframes fadein9{
    0% { opacity:0; }
    45% { opacity:0; }
    60% { opacity:1; }
    95% { opacity:1; }
    100% { opacity:0; }
}

@-webkit-keyframes fadein9{
    0% { opacity:0; }
    45% { opacity:0; }
    60% { opacity:1; }
    95% { opacity:1; }
    100% { opacity:0; }
}
.six-logos10{
    -webkit-animation: 10s ease 0s normal forwards infinite fadein10;
    animation: 10s ease 0s normal forwards infinite fadein10;
  
} 
@keyframes fadein10{
    0% { opacity:0; }
    50% { opacity:0; }
    65% { opacity:1; }
    95% { opacity:1; }
    100% { opacity:0; }
}

@-webkit-keyframes fadein10{
    0% { opacity:0; }
    50% { opacity:0; }
    65% { opacity:1; }
    95% { opacity:1; }
    100% { opacity:0; }
}
.six-logos11{
    -webkit-animation: 10s ease 0s normal forwards infinite fadein11;
    animation: 10s ease 0s normal forwards infinite fadein11;
  
} 
@keyframes fadein11{
    0% { opacity:0; }
    55% { opacity:0; }
    70% { opacity:1; }
    97% { opacity:1; }
    100% { opacity:0; }
}

@-webkit-keyframes fadein11{
    0% { opacity:0; }
    55% { opacity:0; }
    70% { opacity:1; }
    97% { opacity:1; }
    100% { opacity:0; }
}
@media (max-width: 1200px) {
    .six-logos-start{
        grid-column: 2/7;
    }
    .six-logos-start img{
        width: 100%;
     }
    

}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .six-logos-start{
        grid-column: 1/7;
        margin-top: 5%;
    }
    .six-logos-start img{
        width: 100%;
     }

  
}
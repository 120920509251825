.legalFooterBox{
    width: 100vw;
   /*  height: 290px; */
    background-color: #1e0606;
}
.wrapper{
    display: grid;
    grid-template-columns: repeat(14, 1fr);
   grid-column-gap: 20px;
   padding: 60px 50px;
   
  
}
.mobile-wrapper{
    margin: 0;
    padding: 20px;
}
.dataFiscalLogo{
    grid-column: 1/1;
    margin-top: 4px;
}
.data-logo-mobile{
    float:left;
    padding-right: 10px;
}
.legalText{
    grid-column: 2/6;
}
.legalText p{
    font-size: .7em;
    color: #b7b6b6;
    line-height: 1.5em;
}
.fiscalLogo{
     width: 70px;
}
.ssnLogo{
    width: 70px;
    margin: auto;
    padding-top: 2px;  
}
.ssnLogoBox{
    grid-column: 1/1;
    margin-top: 4px;
    width: 70px;
    height: 40px;
    background-color: #fff;
}
.ssn-logo-mobile{
    margin-top: 4px;
    width: 40px;
    height: 26px;
    background-color: #fff;
    float:left;
   margin-right: 10px;

}
.ssnText{
    grid-column: 2/6;
}
.ssnText p{
    font-size: .7em;
    color: #b7b6b6;
    line-height: 1.5em;
}
.registroLogoBox{
    grid-column: 1/5;
    
}
.registroLogo{
    margin-top: 4px;
    width: 100%;
    
}
.service{
    grid-column: 7/14;
    grid-row: 1/3;
}
.service-text{
    font-size: 12px;
    color: #969494;
}
.service-sub{
    font-size: 13px;
    color: #b7b6b6;
    margin-top: 20px;
    font-weight: 600;
}
.service-responsables{
    margin-top: 8px;
    display: flex;
}
.service-responsables h5{
    color: #969494;
    margin-top: 4px;
   
}
.service-person-box{
    margin-right: 24px;
    margin-bottom: 16px;
}
.service-person{
    font-weight: 700;
    color: #b7b6b6;
    margin-bottom: 2px;
}
.circle-box{
    text-align: center;
    padding: 80px;
}
.circle-logo{
    width: 28px;
    margin: auto;
    
}
.sign{
    padding-left: 40px;
    font-size: 11px;
    color:#969494;
    padding-bottom: 16px;
}
.mobile-sign{
    padding-left: 10px;
    font-size: 11px;
    color:#969494;
    padding-bottom: 16px;
    margin-top: 30px;
}
.sign-dev{
   
    font-size: 11px;
    color:#969494;
    padding-bottom: 16px;
}
.mobile-sign-dev{
  
    font-size: 11px;
    color:#969494;
    padding-bottom: 16px;
    margin-top: 30px;
}
.protected-data{
    color: #c36c81;
    font-size: 13px;
    border-bottom: 1px solid #c36c81;
}


@media (max-width: 1064px) {
    .legalFooterBox{
        height: auto;
    }  
   }
@media (max-width: 1000px) {
   
    .fiscalLogo{
        width: 40px;
        margin-top: 0;
    }
    .ssnLogoBox{
        grid-column: 1;
        width: 40px;
        height: 25px;
    }
    .ssnLogo{
        width: 40px;
    }
    .ssnText{
        grid-column: 2/5;
        
    }
    
}
@media only screen and (min-width: 340px) and (max-width: 767px){
    .legalText{
        grid-column: 2/15;
        margin-bottom: 10px;
    }
    .wrapper{
     
       grid-gap: 5px;
       padding: 20px;

    }
    .dataFiscalLogo{
        margin-right: 5px;
    }
    .ssnLogo{
        margin-right: 5px;

    }
    .service{
        padding-top: 10px;
    }

    .service-responsables{
        flex-direction: column;
    }
        .service-mobile{
            padding-top: 1rem;
            font-size: 12px;
    color: #969494;

        }
        .mobile-dev{
            
            color: #969494;
        }

}
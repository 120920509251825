.menu-box{
    width: 170px;
    display: flex;
    flex-direction: column;
    
}
.menu-link{
    display:block;
    text-decoration: none;
    color: #1e0606;
    border-bottom: 1px solid #9b2743;
    font-size: 11px;
    margin-bottom: 8px;
    padding-bottom: 8px;
    font-family: 'Sora', sans-serif;
}
.home-menu-link{
    display:block;
    text-decoration: none;
    color: #1e0606;
    font-size: 12px;
    font-weight: 700;
}
.last-menu-link{
    display:block;
    text-decoration: none;
    color: #1e0606;
    border-bottom: 1px solid #9b2743;
    margin-bottom: 7px;
    padding-bottom: 7px;
    font-size: 12px;
    margin-top: 30px;
    border-top: 1px solid #9b2743;
    padding-top: 7px;
    font-weight: 700;   
}
.last-menu-link-selected{
    display:block;
    text-decoration: none;
    color:#9b2743;
    border-bottom: 1px solid #9b2743;
    margin-bottom: 7px;
    padding-bottom: 7px;
    font-size: 12px;
    margin-top: 30px;
    border-top: 1px solid #9b2743;
    padding-top: 7px;
    font-weight: 800;  
}
.display-none{
    display: none;
}
.footerBox {
  background-color: #9b2743;
  width: 100vw;
  height: 380px;
  padding-bottom: 50px;
}
.footer-in {
  padding: 20px  0 0 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0 40px 0;
  column-gap: 1em;
}
.footer-in img {
  width: 10%;
  padding: 0 40px 0 0;
  margin: 0;
}
.footerMenu {
  display: flex;
  flex-direction: column;
  padding: 13px 0;
}
.footerItem {
  padding-bottom: 10px;
  border-bottom: 1px solid #b7b6b6;
}
.footerItem:last-child {
  border-bottom: none;
}
.footerCategory {
  display: inline-block;
  position: relative;
  margin-bottom: 5px;
  margin-top: 18px;
}
.footerCategory:hover {
  font-weight: 700;
  color: #fff;
}
.contactBox {
  font-family: "Sora", sans-serif;
  color: #fff;
  flex-grow: 3;
  padding: 0 0 0 30em;
  align-self: flex-end;
}
.contactBox p {
  margin: 0;
}
.footerEmail {
  font-weight: 800;
}
.icon-circle {
  background-color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #9b2743;
  display: flex;
}
.social-icon {
  width: 28px;
  height: 28px;
  padding: 6px 0 0 6px;
  color: #9b2743;
}
.social-icon-big {
  width: 30px;
  height: 30px;
  padding: 5px 0 0 5px;
  color: #9b2743;
}
.social-box {
  margin: 20px 0 30px 0;
  display: flex;
  column-gap: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .contactBox {
    padding: 0 0 0 20%;
  }
  .footer-in img {
    width: 18%;
    margin-top: 12px;
    padding: 0 20px 0 0;
  }
  .social-icon {
    width: 24px;
    height: 24px;
    padding: 6px 0 0 6px;
  }
  .social-icon-big {
    width: 24px;
    height: 24px;
    padding: 5px 0 0 5px;
  }
  .icon-circle {
    width: 36px;
    height: 36px;
  }
}
@media only screen and (min-width: 1023px) and (max-width: 1261px) {
  .contactBox {
    padding-left: 300px;
  }
}

.logo-menu-box-mobile {
  width: 100vw;
  box-sizing: border-box;
  background-color: #9b2743;
  display: flex;
  gap: 30px;
  padding: 10px 20px 80px;
  justify-content: center;
}
.logo-box-mobile{
  width: 35%;
}
.logo-box-mobile img {
  width: 100%;
  margin: 20px 0px 0 0;
}
.menu-footer-box{
display: flex;
flex-direction: column;
padding-top: 30px;
width: 50%;

}
.footer-item-mobile{
  padding-bottom: 10px;
  border-bottom: 1px solid #b7b6b6;
}
.footer-category-mobile{
  margin-bottom: 5px;
  margin-top: 18px;
}
.footer-item-mobile:last-child {
  border-bottom: none;
}
.social-box-mobile {
  box-sizing: border-box;
  align-items: center;
  width: 100vw;
  display: flex;
  justify-content: center;
  column-gap: 20px;
}
.home-mobile-footer {
  margin: 50px auto;
  color: #b7b6b6;
  text-align: center;
  border: 1px solid #b7b6b6;
  border-radius: 10px;
  width: fit-content;
  padding: 2px 16px;
}
.afzonline-box {
  width: 100%;
  text-align: center;
  margin: 50px auto;
}
.afzonline-link {
  font-family: "Sora", sans-serif;
  font-weight: 800;
  text-decoration: none;
  color: #fff;
  padding: 3px 10px;
  border-radius: 20px;
  border: 1px solid #fff;
}

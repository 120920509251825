
.subMenuBox{
    display: flex;
    border-bottom: 1px solid #D8CECE;
    padding-bottom: 5px;

}
.subMenuBox :nth-child(4){
    flex-grow: 1.5;
}
.subMenuBox :nth-child(4) .menu-item{
    border-right: none;
}
.subMenuBox :nth-child(4) .menu-item-selected{
    border-right: none;
}
.horizontalTitle{
    font-family: 'Sora', sans-serif;
    font-weight: 600;
    font-size: 30px;
    color: #fff;
    margin: 10px 0;
}
.horizontalText{
    margin: 10px 0;  
}
.menu-item{
    font-size: 15px;
    color: #fff;
    font-weight: 300;
    border-right: 1.5px solid #D8CECE;
    padding-right: 8px;
    margin-right: 8px;
}
.menu-item-home{
    padding: 0;
    font-family: 'Sora', sans-serif;
    font-size: 15px;
    color: #fff;  
    font-weight: 700;
}
.menu-item-selected{
    font-size: 15px;
    color: #fff;
    font-weight: 800;
    border-right: 1.5px solid #D8CECE;
    padding-right: 8px;
    margin-right: 8px; 
}

@media (max-width: 1300px) {
    .menu-item{
        font-size: .8em;
    }
    .menu-item-home{
        font-size: .8em;
    }
    .menu-item-selected{
        font-size: .8em;
    }
}     

@media (max-width: 1000px) {
    .menu-item{
        font-size: .7em;
    }
    .menu-item-home{
        font-size: .7em;
    }
    .menu-item-selected{
        font-size: .7em;
    }
        
    }
  
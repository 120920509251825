.headerBox{
    z-index: 100;
    position: absolute;
    width: 100vw;
}
.header-in{
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    column-gap: 30px;
    padding: 10px 40px 0 40px;
    align-items: center;
    justify-content: left;
   
}
.logoAfz{
    width: 100%;
    margin: 0;
    grid-column: 1;
}
.header-menu{
    background-color: #9B2743;
    border-radius: 16px;
    width: 100%;
    margin-top: 15px;
    grid-column: 2/8;
    display: flex;
    justify-content: space-between;
    height: 30px;
    column-gap: 2em;
}
.header-item{
    margin: 4px;
    padding: 1px 10px 0;
}
.header-item:nth-child(4){
    flex-grow: 2;
    
}
.headeritem:last-child{
    font-family: 'Sora', sans-serif;
    color: #fff;
    font-weight: 700;
  
}
.header-category{
    font-family: 'Sora', sans-serif;
    font-weight: 500;
    font-size: 14.5px;
    display: inline-block;
    position: relative;
    margin-bottom: 3px;
    margin-top: 3px;
    color: #B7B6B6;
   
}
.header-category:visited{
    color: #B7B6B6;
}
.header-category::after{
    font-family: 'Sora', sans-serif;
    font-weight: 600;
    color: #B7B6B6;
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 3px;
    bottom: -5px;
    left:0;
    background-color:#fff; 
    transform-origin: right;
    transition: transform 0.25s ease-out;
}
.header-category:hover::after{
    transform: scaleX(1);
    transform-origin: left;
}
.header-category:hover{
    font-family: 'Sora', sans-serif;
    font-weight: 700;
    color: #fff;
}
.header-category-selected{
    display: inline-block;
    font-family: 'Sora', sans-serif;
    font-weight: 600;
    font-size: 14.5px;
    color: #fff;
    border-bottom: 3px solid #fff;
    margin-bottom: 3px;
    margin-top: 3px;
    padding-bottom: 2px;  
}
.header-category-special{
    font-weight: 800;
    font-size: 14.5px;
    color: #fff;
    
}
.flag-box{
    padding: 5px 5px 3px 5px;
    background-color: #1e0606;
    position: absolute;
    margin-left: 90%;
    box-sizing: border-box;
}
@media only screen and (min-width: 1440px){
    .hero{
        width: 100vw;
    }
    .header-category {
        font-size: 15px;
    }
    .logoAfz{
        width: 90%;
    }
}
@media only screen and (min-width: 768px) and (max-width: 1023px){
    .header-category{
        font-size: 12px;
       }
    .header-category-selected{
        font-size: 12px;
    }
    .header-menu{
        column-gap: 1em;
        grid-column: 1/8;
        margin-top: 5px;
        height: 28px;
        }
        .header-in{
            column-gap: 15px;
        }
        .logoAfz{
            width: 120%;
        }
    }
    @media only screen and (min-width: 330px) and (max-width: 768px){
.headerMobileBox{
    z-index: 100;
    position: absolute;
    width: 100vw;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}
.hidden{
    z-index: 100;
    position: absolute;
    width: 100vw;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow-x: hidden;
}
.logoAfz-mobile{
    width: 35%;
    margin-left: 10px;
    margin-top: 4px;
}

.navbar-open{
    box-sizing: border-box;
    background-color: #1e0606;
    position: absolute;
    width: 35%;
    left: 65vw;
    margin-top: 0;
    display: flex;
    flex-direction: column;
    padding: 60px 10px 40px 0;
    align-items:flex-end;
    transition: left 0.3s linear;
    height: 290px;
}
.out{
    box-sizing: border-box;
    background-color: #1e0606;
    position: absolute;
    width: 35%;
    left: 100vw;
    margin-top: 0;
    display: flex;
    flex-direction: column;
    padding: 60px 10px 40px 0;
    align-items:flex-end;
    transition: left 0.3s linear;
    height: 290px;
}
.ham-button{
    position: absolute;
    margin-top: 1.5em;
    margin-left: 88%;
}
.mobile-item{
    font-family: 'Sora', sans-serif;
    font-weight: 500;
    text-align: right;
    padding: 10px;
    color: #fff;
    font-size: .9em;
}
.mobile-item-special{
    font-weight: 800;
    text-align: right;
    margin: 10px 4px;
    color: #fff;
    font-size: .9em;
    background-color: #9B2743;
    padding: 3px 8px;
    border-radius: 10px;
}
.flag-box-mobile{
  margin-right: 10px;
}
.x-container{
    display: flex;
    align-items: center;
    justify-content: left;
    margin-left: -30px;

}
    }
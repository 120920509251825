.four-logos-start{
    grid-row: 2;
    grid-column: 3/7;
    margin-top: 35%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 15px;    
}
.four-logos1{
    -webkit-animation: 6s ease 0s normal forwards infinite fadein1;
    animation: 6s ease 0s normal forwards infinite fadein1;
  
} 
@keyframes fadein1{
    0% { opacity:0; }
    15% { opacity:0; }
    30% { opacity:1; }
    95% { opacity:1; }
    100% { opacity:0; }
}

@-webkit-keyframes fadein1{
    0% { opacity:0; }
    15% { opacity:0; }
    30% { opacity:1; }
    95% { opacity:1; }
    100% { opacity:0; }
}
.four-logos2{
    -webkit-animation: 6s ease 0s normal forwards infinite fadein2;
    animation: 6s ease 0s normal forwards infinite fadein2;
  
} 
@keyframes fadein2{
    0% { opacity:0; }
    23% { opacity:0; }
    38% { opacity:1; }
    95% { opacity:1; }
    100% { opacity:0; }
}

@-webkit-keyframes fadein2{
    0% { opacity:0; }
    23% { opacity:0; }
    38% { opacity:1; }
    95% { opacity:1; }
    100% { opacity:0; }
}
.four-logos3{
    -webkit-animation: 6s ease 0s normal forwards infinite fadein3;
    animation: 6s ease 0s normal forwards infinite fadein3;
  
} 
@keyframes fadein3{
    0% { opacity:0; }
    31% { opacity:0; }
    46% { opacity:1; }
    95% { opacity:1; }
    100% { opacity:0; }
}

@-webkit-keyframes fadein3{
    0% { opacity:0; }
    31% { opacity:0; }
    46% { opacity:1; }
    95% { opacity:1; }
    100% { opacity:0; }
}
.four-logos4{
    -webkit-animation: 6s ease 0s normal forwards infinite fadein4;
    animation: 6s ease 0s normal forwards infinite fadein4;
  
} 
@keyframes fadein4{
    0% { opacity:0; }
    38% { opacity:0; }
    53% { opacity:1; }
    95% { opacity:1; }
    100% { opacity:0; }
}

@-webkit-keyframes fadein4{
    0% { opacity:0; }
    38% { opacity:0; }
    53% { opacity:1; }
    95% { opacity:1; }
    100% { opacity:0; }
}
@media only screen and (min-width: 1023px) and (max-width: 1137px){
    .four-logos-start{
        grid-column: 2/6;
    }
}
@media (max-width: 1000px) {
    .four-logos-start{
        grid-column: 1/7;
    }
    .four-logos-start img{
        width: 100%;
     }
}
@media only screen and (min-width: 1440px){
    .four-logos-start{
        margin-top: 40%;
    }
}
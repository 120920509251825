.work-backgound{
    background: url(../../../public/img/CV.jpg);
    background-size: cover ;
    min-height: 110vh;
    width: 100vw;

}
.work-hero{
    width: 100vw;
    box-sizing: border-box;
    position: absolute;
    margin-top: 7%;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 30px;
    padding: 20px 40px 0 40px; 
}
.work-block{
    grid-column: 2/5;
}
.sub-menu-work{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #fff;
    padding-bottom: 3px;
}
.item-work-selected{
    color: #fff;
}
.work-title{
    color: #fff;
    grid-column: 2/5;
    margin-top: -20px;
}
.cv-form{
    grid-column: 2/4;
    margin-top: -20px;
}
.cv-attach{
    color: #fff;
    font-family: 'Sora', sans-serif;
    font-size: .8em;
    font-weight: 800;
    margin: 5px 0;
}
.cv-send{
    margin-top: 30px;
}
@media only screen and (min-width: 1440px){
    

}
@media only screen and (min-width: 1023px) and (max-width: 1261px){
    .work-block{
        grid-column: 2/6;
    }
    .cv-form{
        grid-column: 2/5;
    }
  
   

}
@media only screen and (min-width: 768px) and (max-width: 1023px){
    .work-backgound{
        min-height: 70vh;
    }
    .work-hero{
        margin-top: 15%;
    }
    .work-block{
        grid-column: 2/7;
    }
    .cv-form{
        grid-column: 2/7;
    }
   
} 
@media only screen and (min-width: 330px) and (max-width: 768px){
    .cv-form{
        margin-top: 24px;
    }
}

.fraud-background{
    position: relative;
    min-height: 740px;
}
.fraud-title{
    grid-column: 2/8;
    border-bottom: 1px solid #b7b6b6;
    height: 40px;
}
.fraud-menu{
    grid-row: 2;
    margin-top: 10px;
}
.fraud-box{
    grid-column: 2/8;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 30px;
}
.fraud-column-1{
    color: #1e0606;
    font-size: .8em;
    margin-top: 10px;
}
.fraud-caracter{
    width: 20px;
    height: 20px;
    box-sizing: border-box;
    color: #fff;
    background-color: #9b2743;
    text-align: center;
    font-weight: 800;
    padding-top: 1px;
    margin: 10px 7px 0 0;
}
.fraud-column-2{
    display: flex;
    justify-content: left;
}
.fraud-text p{
   color: #1e0606;
   font-size: .8em;
   margin-top: 10px;
   flex-shrink: 2;
}
.fraud-subtitle{
    font-size: .9em;
    padding: 16px 0 10px 0;
    color: #9b2743;
}
.second-box{
    grid-column: 2/4;
    margin-top: 16px;
}
.second-text{
    color: #1e0606;
}
.second-name{
    color: #1e0606;
    font-weight: 600;
    padding-top: 5px;
}
.second-phone{
    color: #1e0606;
    padding-top: 3px;
}
.second-mail{
    color: #9b2743;
}
.second-boxes{
    grid-column: 2/4;
}
@media only screen and (min-width: 1023px) and (max-width: 1261px){
    .fraud-items{
        grid-column: 1/3;
        padding-top: 24px;
    }
    .fraud-in-box{
        grid-column: 1/3;
    }
    .second-boxes{
        grid-column: 1/3;
    }
    .fraud-background{
        min-height: 800px;
    }
    .second-text{
        font-size: .8em;
    }
   

}
@media only screen and (min-width: 768px) and (max-width: 1023px){
    
    .fraud-items{
        grid-column: 1/4;
        margin-top: 16px;
    }
    .fraud-column-1{   
        font-size: .8em; 
    }
    .fraud-subtitle{
        font-size: .7em;
    }
    .final-list{
        font-size: .7em;
    }
    .fraud-text p{
        font-size: .7em;
    }
    .fraud-in-box{
        grid-column: 1/4;
    }
    .second-boxes{
        grid-column: 1/4;
    }
    .second-text{
        font-size: .8em;
    }
    .fraud-background{
       min-height: 700px;
    }
}
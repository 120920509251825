.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  .modal-content {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 40%;
    max-width: 90%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
  }
  .complain-text{
    color: #000;
    padding: 12px 0;
  }
  .modal-content span{
    color: #000;
    font-weight: 700;
  }
  .modal-form{
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .modal-input{
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #000;
    border-radius: 5px;
    font-size: 1rem;
    font-family: 'Sora', sans-serif;
  }
  .modal-textarea{
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #000;
    border-radius: 5px;
    font-size: 1rem;
    font-family: 'Sora', sans-serif;
    min-height: 5rem;
  }
  .modal-buttons-box{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  .modal-button{
    background-color: #9b2743;
    border: none;
    border-radius: 16px;
    font-family: 'Sora', sans-serif;
    font-size: 13px;
    font-weight: 600;
    color: #fff;
    letter-spacing: 0.5px;
   
    padding: 8px 16px;
    text-align: center;
  }
  .modal-button-secondary{
    background-color: #fff;
  border: #afaeae 1px solid;
    border-radius: 16px;
    font-family: 'Sora', sans-serif;
    line-height: 10px;
    font-size: 13px;
    font-weight: 600;
    color: #afaeae;
    letter-spacing: 0.5px;
   
    padding: 4px 16px;
    text-align: center;
  }
  .error-modal{
    color: #9b2743;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  @media only screen and (min-width: 768px) and (max-width: 1023px){
    .modal-content{
      width: 70%;
     
    }
  }
  @media (max-width:764px){
    .modal-content{
      width: 100%;
     
    }
  }
.press-background-mobile{
    width: 100vw;
}
.mobile-content{
    padding: 20% 20px 20px;
    width: 100%;
    box-sizing: border-box;
}
.head-note-mobile{
    width: 100%;
    margin-bottom: 16px;
    color: #B7B6B6;
    font-size: 15px;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #B7B6B6;
    padding-bottom: 4px;
}
.title-press-mobile{
    text-transform: uppercase;
    padding-bottom: 8px;
}
.box-text-mobile{
    margin-top: 10px; 
    overflow: hidden;
    text-overflow: ellipsis;
    color: #1e0606;
    font-family: 'Sora', sans-serif;
    font-size: 14px;
    line-height: 20px; 
}
.more-mobile{
    box-sizing: border-box;  
    margin-top: 8px;
    text-align: center;
    color: #fff;
    display:flex;
    flex-direction: row;
    justify-content: flex-end;
}
.see-mobile{
    font-weight: 500;
    padding-top: 5px;
    color: #B7B6B6;
    text-transform: uppercase;
}
.see-icon-mobile{
    box-sizing: border-box;
    background-color: #B7B6B6;
    width: 30px;
    height: 30px;
   
}
.icon-on-mobile{
    color: #fff;
    width: 1.8rem;
    height: 1.8rem;
    box-sizing: border-box;
}
.card-press-mobile{
    margin: 20px 0;
}
.see-card{
   align-self: flex-end;
   color: #B7B6B6;
}
.card-note-mobile{
    padding: 12px 0 20px;
    max-height: 430px;
}
.box-text-mobile-detail p{
    margin-top: 10px; 
    color: #1e0606;
    font-family: 'Sora', sans-serif;
    font-size: 14px;
    line-height: 20px; 

}
.card-container-staff{
    width: 100%;
    padding-bottom: 20%;
    
}
.card-title{
    width: 90%;
    background-color: rgba(155, 39, 67, 0.8);
    border-radius: 10px;
    margin: -70% 5% 20%;
    position: relative;
    text-align: center;
    padding: 2px;
  
}
.card-name{
    font-size: 12px;
   font-weight: 700;
   color: #fff;
  
}
.card-position{
    font-weight: 400;
    font-size: 10px;
   
}
.staffPositions{
    font-weight: 600;
    height: 30px;
    color:#fff;
}
.staffPositions h5{
    font-weight: 600;
   
}
.video-container{
    width: 100%;
    height: 100%;
}
.video{
    width: 100%;
    height: auto;
}


@media (max-width: 1024px) {
    .card-container img{
        width: 100%;
    }
    .card-position{
        font-size: 10px
    }
    .card-name{
        font-size: 12px;
    }
}

.contact-backgound{
    background: linear-gradient(90deg, rgba(255, 254, 254, 0) 35.42%, rgba(255, 255, 255, 0.20) 100%), url(../../../public/img/Contacto.jpg);
    background-size: cover ;
    min-height: 740px;
    width: 100vw;
    margin-bottom: -370px;

}
.contact-grid{
    min-height: 75vh;
    margin: 0px 40px 0 40px;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: 100px 200px 40px;
    grid-gap:24px;
    padding-top: 220px;
 
}
.push{
    height: 366px;
}

.contact-block{
     grid-column: 1/4;
     grid-row: 1;
}
.horizontal-title{
    color: #fff;
    margin-bottom: 10px;
    font-size: 24px;
}
.horizontal-text{
    font-size: 16px;
}
.first-column{
    grid-column: 1/3;
    grid-row: 2;
    margin-top: -20px;
}
.contact-subtitle{
    color: #fff;
    font-size: 1.2em;
    margin-bottom: 10px;
    text-transform: uppercase;
}
.highlight-top{
    margin-top: 10px;
    font-weight: 700;
}
.highlight{
    font-weight: 700;
}
.second-column{
    margin-top: -20px;
    grid-column: 3;
    grid-row: 2;
}
.contact-form{
    grid-column: 5/7;
    grid-row: 1/ span 2;
}
.contact-thanks{
    color: #fff;
    margin-bottom: 10px;
}
.contact-thanks-text{
    margin-bottom: 16px;
    font-weight: 500;
}
.work-button-in-contact{
    grid-column: 1;
    justify-content: left;
}
.radio-title{
    margin-right: 30px;
    font-weight: 700;
    margin-bottom: 3px;
    font-size: .8em;
}
.network-error{
    background-color: #9b2743;
    padding: 4px;
    margin-top: 10px;
    border-radius: 5px;
    text-align: center;
    font-weight: 500;
}
.contact-complain{
    grid-column: 1/4;
    grid-row: 4;
}

  .close-button {
    background: #e74c3c;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
.radio-label{
    margin-right: .5rem;
}
@media only screen and (min-width: 768px) and (max-width: 1023px){
    .contact-backgound{
        height: 80vh;
    }
    .contact-grid{
       row-gap: 15px;
       grid-template-rows: 100px 150px 180px;
    }
    
    .contact-block{
        grid-column: 1/4;
        
   }
   .contact-block h5{
        font-size: 16px; 
    }
    .contact-block p{
        font-size: 11px; 
        }
    .first-column{
        margin-top: 0;
    }
    .first-column h6{
        font-size: .8em;
        }
    p{
        font-size: 12px ;
    }
    .second-column h6{
        font-size: .8em;
    }
    .second-column{
        grid-column: 1/3;
        grid-row: 3;
        margin-top: 0;
       
    }
    .contact-form{
        grid-row: 1/3;
        grid-column: 4/8;
    }
 
   }

/* @media only screen and (min-width: 330px) and (max-width: 768px){ */
    .first-box-mobile{
        height: 290px;
        width: 100%;
        box-sizing: border-box;
        margin-top: -370px;
       
    }
    .img-box{
        width: 50%;
        text-align: right;
        margin-left: 50%;
        height: 290px;
        overflow: hidden;
    }
    .img-box img{
        
        object-fit: cover;
        height: 100%;
    } 
    .sub-menu-box-contacto{
        display: flex;
        flex-direction: column;
        margin-top: 100px;
        margin-left: 20px;
        height: 150px;
        position: absolute;
        justify-content: space-around;
        width: 40%; 
    }
    .bordeax-background{
        box-sizing: border-box;
        background-color: #9b2743;
        padding: 20px 20px 40px 20px;
       
    }
    .bordeax-background h5{
        font-size: 1em;
        font-weight: 600;
    }
    .first-column{
        margin-top: 16px;
    }
   .second-column{
    margin-top: 16px;
   }
   .contact-form{
    margin-top: 24px;
   }
   .network-error{
    background-color: #fff;
    font-weight: 700;
    color: #9b2743;
}

@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;600;700;800&display=swap');

body{
 margin: 0;
 padding: 0;
 height: 100%;
 overflow-x: hidden;
 max-width: 100%
}
h1{
    margin: 0;
    padding: 0;
    font-family: 'Sora';
    font-weight: 400;
    color: #fff;
    font-size: 4.7em;
    line-height: 1em;
    letter-spacing: -0.04em;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: .35em;
}
h2{
    margin: 0;
    padding: 0;
}
h3{
    margin: 0;
    padding: 0;
}
h4{
    margin: 0;
    padding: 0;
    font-family: 'Sora', sans-serif;
    font-weight: 600;
    font-size: 14.5px;
    color: #B7B6B6;
}
h5{
    font-family: 'Sora', sans-serif;
    margin: 0;
    padding: 0;
    
}
h6{
    font-family: 'Sora', sans-serif;
    margin: 0;
    padding: 0;

}
p{ margin: 0;
    padding: 0;
    font-family: 'Sora', sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #fff;
}
div{
    margin: 0;
    padding: 0;
}
a{
    text-decoration: none;
}
label{
    margin: 0;
    padding: 0;
    font-family: 'Sora';
    font-size: .7em;
    font-weight: 600;
}
.floating {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 60px;
    right: 15px;
   /*  background-color: #25d366; */
    color: #fff;
    border-radius: 50px;
    text-align: center;
    font-size: 40px;
 /*    box-shadow: 2px 2px 3px #999; */
    z-index: 100;
   }

   .icon {
    margin-top: 16px;
    filter: drop-shadow(3px 3px 4px rgba(0, 0, 0, 0.25));
   }

   .top-to-btm{
    position: relative;
  }
  .icon-position{
    position: fixed;
    bottom: 60px;
    left: 25px;
    z-index: 20;
  }
  .icon-style{
    background-color: #9b2743;
    border: 2px solid #fff;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    color: #fff;
    cursor: pointer;
    animation: movebtn 3s ease-in-out infinite;
    transition: all .5s ease-in-out;
  }

  @keyframes movebtn {
    0%{
      transform: translateY(0px);
    }
    25%{
      transform: translateY(10px);
    }
    50%{
      transform: translateY(0px);
    }
    75%{
      transform: translateY(-10px);
    }
    100%{
      transform: translateY(0px);
    }
  }
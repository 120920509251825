.product-background{
    margin-bottom: -370px;
    min-height: 97%;
}
.product-content{
    padding-top: 140px; 
}
.push {
    height: 366px;
  }
.product-box{
    margin: 0 40px 24px;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: 50px auto;
    column-gap: 30px;
    align-items: flex-start;
   
}
.menu-box{
    width: 180px;
    display: flex;
    flex-direction: column;
    grid-column: 1;
    grid-row: 1 / 3;
}
.menu-link{
    display:block;
    text-decoration: none;
    color: #1e0606;
    border-bottom: 1px solid #9b2743;
    font-size: 12px;
    margin-bottom: 7px;
    padding-bottom: 7px;
    font-weight: 400;
    cursor: pointer;
}
.menu-link-selected{
    display:block;
    text-decoration: none;
    color: #9b2743;
    border-bottom: 1px solid #9b2743;
    font-size: 11.8px;
    margin-bottom: 7px;
    padding-bottom: 10px;
    font-weight: 600;
    line-height: 15px;
    cursor: pointer;
}
.home-menu-link{
    display:block;
    text-decoration: none;
    color: #1e0606;
    font-size: 12px;
    font-weight: 700;
}
.last-menu-link{
    display:block;
    text-decoration: none;
    color: #1e0606;
    border-bottom: 1px solid #9b2743;
    margin-bottom: 7px;
    padding-bottom: 7px;
    font-size: 12px;
    margin-top: 40px;
    border-top: 1px solid #9b2743;
    padding-top: 10px;
    font-weight: 700;   
}
.title-box{
    grid-column:2/8;
    grid-row: 1;
    column-gap: 30px;
    line-height: 20px;
    border-bottom: 1px solid #B7B6B6;
    display: grid ;
    grid-template-columns: repeat(3, 1fr);

}
.product-titles{
    grid-column: 1/3;
    
}
.product-title{
    font-family: 'Sora', sans-serif;
    font-weight: 700;
    font-size: 30px;
    color: #1e0606;
    margin: 10px 0;
    
}
.product-subtitle{
    color: #B7B6B6;
    font-weight: 800;
    line-height: 10px;
}
.requisites-title{
    grid-column: 3;
    display: flex;
    align-items: flex-end;
    margin: 13px 0;
}
.product-text{
    grid-column: 2/5;
    grid-row: 2;
    margin: 8px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 300px;
}
.product-text p{
    color: #1e0606;
    margin-top: 5px;
}
.requisites{
    position: absolute;
    margin-top: 50px; 
    width: 25.1%;   
    -webkit-animation: 1s ease-in 1s 1 reverse both running slidein;
    animation: 1s ease-in 1s 1 reverse both running slidein;
}
@keyframes slidein{
    0%{translate:276%}
    100%{translate: 500%;}
}
@-webkit-keyframes slidein{
    0%{translate:276%}
    100%{translate: 500%;}
}
.requisites-again{
    position: absolute;
    margin-top: 50px; 
    width: 26%;   
    margin-left: 71%;
}
.requisites-box{
    background-color: #B7B6B6;
    padding: 10px;
    margin-bottom: 2px;
}
.requisites-subtitle{
    font-weight: 700;
    color: #fff;
    font-size: 13.5px;
    margin-bottom: 5px;
}
.requisites-text{
    font-size: 12.5px;
    font-weight: 500;   
}
.product-photo{
    width: 100vw;
    height: 28vh;
    object-fit: cover;
    
}
.display-none{
    display: none;
}
.list p{
    font-weight: 600;
    font-size: 13px;
    color: #1e0606;
}
.extra-subtitle{
    text-transform: uppercase;
    font-weight: 600; 
    padding-top: 5px;
}
.extra-text{
    margin-bottom: 10px;
}
.extra-text p{
    font-size: 13px;
    font-weight: 300;
    color: #1e0606;
}
.product-button{

    align-self: flex-end;

}
.call-to-action{
    margin-top: 40px;
    width: 100%;
}

.call-to-action p{
   letter-spacing: .5px ;
   color:#9b2743;
   font-weight: 500;
}
.call-to-action-en{
    margin-top: 40px;
    width: 100%;
    display: flex;
    align-items: baseline;
}
.call-to-action-en p{
    letter-spacing: .5px ;
    color:#9b2743;
    font-weight: 500;
    margin-left: 8px;
}

@media only screen and (min-width: 1460px){
    .product-box{
      padding-bottom: 6%;
      
    }
  
}
@media only screen and (min-width: 1023px) and (max-width: 1137px){
    .product-title{
        font-size: 24px;
    }
    .product-background-extra{
        min-height:174vh; 
    }
    .requisites-subtitle{
        font-size: 12px;
    }
    .requisites-text{
        font-size: 11px;
    }
    .requisites-box{
        padding: 7px;
    }
 
}

@media only screen and (min-width: 1137px) and (max-width: 1340px){
    .requisites-subtitle{
        font-size: 12px;
    }
    .requisites-text{
        font-size: 11px;
    }
    
}
@media only screen and (min-width: 768px) and (max-width: 1023px){
    .product-text{
        grid-column: 2/6;
    }
    .requisites-subtitle{
        font-size: 11px;
    }
    .requisites-text{
        font-size: 10px;
    }
    .requisites-box{
        padding: 5px;
    }
    .product-title{
        font-size: 18px;
    }
    .call-to-action p{
        font-size: 11px;
    }
    .menu-box{
        width: 135px;
    }
    .menu-link{
        font-size: 10px;
    }
    .menu-link-selected{
        font-size: 10px;
    }
    .product-background-extra{
        min-height:164vh; 
    }
    .list p{
        font-size: 11px;
    }
    .product-text p{
        font-size: 12.5px;
    }
 
}

.homeBackgound{
    background-size: cover;
    height: 97vh;
    width: 100%;
    
}
.home-video{ 
    object-fit: cover;
    height: 97vh;
    width: 100vw;
}
.hero{
    box-sizing: border-box;
    position: absolute;
    margin-top: 28%;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 30px;
    padding: 20px 40px 0 40px;
    width: 100vw;
}
.homeTitle{
    grid-column: 2/7;
    display: flex;
    animation: 8s ease 0s normal forwards 1 fadein-button;
    -webkit-animation: 8s ease 0s normal forwards 1 fadein-button;
    opacity:1;
    height: 200px;
    align-items: flex-end;
    margin-top: -25px;
    margin-bottom: 10px;
}
@keyframes fadein{
    0%{opacity:0}
    70%{opacity:0}
    100%{opacity:1}
}
@-webkit-keyframes fadein{
    0%{opacity:0}
    70%{opacity:0}
    100%{opacity:1}
}
.homeTitle h1{
    margin-bottom: 0;
}
.button-box{
    grid-column: 2/7;
    animation: 4s ease 0s normal forwards 1 fadein-button;
    -webkit-animation: 4s ease 0s normal forwards 1 fadein-button;
    opacity:1
}
.mobile-button-box{
    width: 100%;
    animation: 4s ease 0s normal forwards 1 fadein-button;
    -webkit-animation: 4s ease 0s normal forwards 1 fadein-button;
    opacity:1

}
.dark-button-mobile-home{
    background: rgba(0, 0, 0, 0.3);
    border: 1.5px solid #fff;
    box-shadow: 3px 3px 4px 3px #00000026;
    border-radius: 24px;
    width: 100%;
    height: 38px;
    font-family: 'Sora', sans-serif;
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 12px;
}



@keyframes fadein-button{
    0%{opacity:0}
    100%{opacity:1}
}

@-webkit-keyframes fadein-button{
    0%{opacity:0}
    100%{opacity:1}
}
.hero-mobile{
    box-sizing: border-box;
    position: absolute;
    margin-top: 80%;
    display: flex;
    flex-direction: column;
    padding: 20px 40px 0 40px;
    justify-content: center;
   
}
@keyframes fadeinMobile{
    0%{opacity:0}
    90%{opacity:0}
    100%{opacity:1}
}

@-webkit-keyframes fadeinMobile{
    0%{opacity:0}
    90%{opacity:0}
    100%{opacity:1}
}
.gradient{ 
    background: linear-gradient(90deg, rgba(30, 29, 29, 0.5 ), rgba(253, 248, 248, 0)); 
    width: 100%;
    height: 97vh;
    position: absolute;
    margin-top: -98vh;
}



@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .homeTitle{
        padding-left: 0;
        grid-column: 2/8;
    }
    h1{
       font-size: 3em; 
    }
    .button-box{
        grid-column: 2/7;
    }
    .homeBackgound{
        height: 60vh;    
    }
    .home-video{ 
        height: 60vh;
    }
    .hero{ 
        margin-top: 60%;
        padding: 20px 40px 0 40px;
    }
    .gradient{
        display: flex;
        align-items: center;
    }
    .dark-button-home{
        margin-bottom: 12px;
    }
  
}
.homeTitle-mobile{
   
    padding-left: 0;
    font-size: .6em;
    animation: 8s ease 0s normal forwards 1 fadein;
    -webkit-animation: 8s ease 0s normal forwards 1 fadein;
    opacity:1;
    margin: 0 auto 12px;
}

.sub-menu-box{
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    margin-left: 20px;
    height: 150px;
    position: absolute;
    justify-content: space-between;
    
}
.menu-item-selected-mobile{
    font-size: 14px;
    color: #9b2743;

}
.menu-item-mobile{
    font-size: 14px;
    color: #b7b6b6;
    font-weight: 400;

}
.item-home-mobile{
    font-size: 14px;
    color: #b7b6b6;
    font-weight: 700;

}
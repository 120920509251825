.forms-background {
  position: relative;
}
.forms-content {
  width: 100vw;
  padding-top: 140px;
  min-height: 630px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.form-box {
  margin: 0 40px;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: 50px auto;
  column-gap: 30px;
  row-gap: 8px;
  align-items: flex-start;
}
.form-title {
  font-family: "Sora", sans-serif;
  font-weight: 700;
  font-size: 30px;
  color: #1e0606;
  grid-column: 2/8;
  border-bottom: 1px solid #b7b6b6;
  margin-top: -3px;
}
.first-col {
  grid-row: 2;
  grid-column: 2/4;
}
.first-col p {
  font-size: 0.8em;
  color: #1e0606;
}
.first-col-second-part {
  grid-row: 3;
  grid-column: 2/4;
}

.grey-item {
  background-color: #b7b6b6;
  margin-top: 3px;
}
.grey-item p {
  color: #fff;
  padding: 7px;
  font-weight: 600;
  margin: 0;
}
.grey-box {
  display: flex;
  margin-top: 3px;
  align-items: center;
  justify-content: center;
}
.grey-item-icon {
  background-color: #b7b6b6;
  height: 30px;
  flex-grow: 2;
}
.grey-item-icon h6 {
  color: #fff;
  padding: 7px;
  font-weight: 600;
  margin: 0;
  font-size: 13px;
}
.icons {
  background-color: #b7b6b6;
  height: 30px;
  width: 30px;
  margin-left: 3px;
  cursor: pointer;
}
.icons-download {
  background-color: #9b2743;
  height: 30px;
  width: 30px;
  margin-left: 3px;
}
.icon-on-grey {
  width: 15px;
  color: #fff;
  width: 2em;
  height: 2em;
  padding-right: 2px;
  box-sizing: border-box;
}
.second-col {
  grid-row: 2;
  grid-column: 4/6;
}
.second-col-second-part {
  grid-row: 3;
  grid-column: 4/6;
}
.second-col p {
  font-size: 0.8em;
  color: #1e0606;
}
.tirth-col {
  grid-row: 2;
  grid-column: 6/8;
}
.tirth-col-second-part {
  grid-row: 3;
  grid-column: 6/8;
  text-align: right;
}
.tirth-col p {
  font-size: 0.8em;
  color: #1e0606;
}
.show-text {
  font-size: 0.95em;
}
.show-text p {
  color: #1e0606;
  font-size: 0.85em;
  padding: 5px 2px;
}
.form-photo-box {
  width: 100vw;
  height: 28vh;
  overflow: hidden;
}
.form-photo-box img {
  object-fit: cover;
  width: 100%;
}
.lavado-button {
  background: rgba(217, 217, 217, 0.3);
  border: 1px solid #9b2743;
  box-shadow: 2px 2px 3px 2px #00000026;
  border-radius: 24px;
  width: 200px;
  height: 28px;
  font-family: "Sora", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #9b2743;
}
.lavado-button:hover {
  background: #9b2743;
  font-weight: 500;
  color: #fff;
  border: 1px solid #d8cece;
}

@media only screen and (min-width: 1461px) {
  .form-box {
    padding-bottom: 8%;
    row-gap: 15px;
  }
  .forms-background {
    min-height: 860px;
  }
  .forms-content {
    min-height: 740px;
  }
}
@media only screen and (min-width: 1023px) and (max-width: 1261px) {
  .grey-item p {
    font-size: 11px;
  }
  .grey-item-icon h6 {
    font-size: 11px;
    line-height: 12px;
    padding: 4px;
  }
  .form-title {
    font-size: 24px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .form-title {
    font-size: 18px;
  }
  .form-box {
    grid-template-rows: 30px auto;
    margin-top: 30px;
    padding-bottom: 5%;
    row-gap: 20px;
  }
  .first-col {
    grid-row: 2;
    grid-column: 2/5;
  }
  .first-col-second-part {
    grid-row: 2;
    grid-column: 5/8;
  }
  .second-col {
    grid-row: 3;
    grid-column: 2/5;
  }
  .second-col-second-part {
    grid-row: 3;
    grid-column: 5/8;
  }
  .tirth-col {
    grid-row: 4;
    grid-column: 2/5;
  }
  .tirth-col-second-part {
    grid-row: 5;
    grid-column: 2/5;
    text-align: left;
    margin-top: -10px
  }
  .lavado-button{
    font-size: 12px;
    width: 160px;
    height: 22px;
  }
  .grey-item p {
    font-size: 10px;
  }
  .grey-item-icon h6 {
    font-size: 10px;
    line-height: 11px;
  }
}

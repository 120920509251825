.custom-alert {
    position: fixed;
    top: 250px;
    left: 50%;
    transform: translateX(-50%);
    padding: 15px 20px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    z-index: 2000;
    text-align: center;
    width: 80%;
    max-width: 400px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);

  }
  
  /* ✅ Estilos según el tipo de alerta */
  .alert-success {
    background-color: #d4edda;
    border: 1px solid #c3e6cb;
  }
  .alert-success p{
    color: #155724;
    font-size: 1.2rem;
  }
  
  .alert-error {
    background-color: #f8d7da;
    border: 1px solid #f5c6cb;
  }
  .alert-error p{
    color: #721c24;
    font-size: 1.2rem;
  }
  @media (max-width:764px){
    .custom-alert{
        width: 70%;
    }
  }
  